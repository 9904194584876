import * as React from "react";
import NotFound from "../components/ErrorPage/NotFound";

const NotFoundPage = () => {
  return (
    <section>
      <NotFound />
    </section>
  )
}

export default NotFoundPage
