import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 2;
  height: 100vh;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  @media screen and (max-width: 980px) {
    top: 0;
  }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    font-family: 'Roboto', sans-serif;
    background: url('//images.ctfassets.net/5zarnsr6dpym/1HEiXBHhBmazjx2au8dwnS/8b4895d76acd9d3c948f4e07cfa9e0bd/homepage-main-image.jpg');
    height: 100vh;
    width: 100%;
    position: relative;
    background-size: cover;
    overflow-y: hidden;
`;

const LinkToHomePage = styled(Link)`
  color: black;
  display: block;
  margin: 1em 0;
  font-size: 1.3em;
`;

const Description = styled.h3`
  font-size: 2.5em;
  font-weight: bold;
  @media screen and (max-width: 980px) {
    font-size: 2em;
  }
`;

export default function NotFound() {
    return (
        <Container>
            <Overlay>
                <div>
                    <Description>PAGE NOT FOUND</Description>
                    <LinkToHomePage to="/">Go back to the home page</LinkToHomePage>
                </div>
            </Overlay>
        </Container>
    );
}